import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "./App.css";
import { color } from "./color";

function getTeamData(teams) {
  return teams.map(t => ({
    team: t,
    data: t.wellisDone.reduce((set, w) => {
      const dateKey = w.doneAt.split("T")[0];
      set[dateKey] = set[dateKey] || [];
      set[dateKey].push(w);
      return set;
    }, {})
  }));
}

function getDaysOfCompetition(startDate, endDate) {
  var date = startDate;

  var today = new Date();
  today.setHours(23);
  today.setMinutes(59);
  var comingDays = [];
  var passedDays = [];
  while (endDate > date) {
    const day = date.getDay();
    if (day !== 0 && day !== 6) {
      (date <= today ? passedDays : comingDays).push(new Date(date));
    }
    date.setDate(date.getDate() + 1);
  }
  return { comingDays, passedDays };
}

function App() {
  const [competition, setCompetition] = useState(null);
  const searchParams = new URLSearchParams(window.location.search);
  const hideHeader = searchParams.get("hideHeader");
  const competitionId = searchParams.get("id");
  useEffect(() => {
    const loadData = async () => {
      const data = await (
        await fetch(
          `https://wellifywebapi.azurewebsites.net/api/TeamCompetition/${competitionId}`
        )
      ).json();
      setCompetition(data || []);
    };
    loadData();
  }, [competitionId]);

  const days = competition
    ? getDaysOfCompetition(
        new Date(competition.startDate),
        new Date(competition.endDate)
      )
    : [];

  if (!competition) {
    return <div />;
  }

  const teamData = getTeamData(competition.teams).map(t => {
    return days.passedDays.reduce(
      (sum, day) => {
        const dayKey = day.toISOString().split("T")[0];
        sum[
          t.data[dayKey] &&
          t.data[dayKey].length / t.team.numberOfParticipants >= 2
            ? "green"
            : "red"
        ]++;
        return sum;
      },
      { name: t.team.name, red: 0, green: 0 }
    );
  });

  const data = {
    labels: teamData.map(t => t.name),
    datasets: [
      {
        stack: "myStack",
        label: "Gröna dagar",
        backgroundColor: color.primary,
        hoverBackgroundColor: color.primaryDarker,
        data: teamData.map(t => t.green)
      },
      {
        stack: "myStack",
        label: "Röda dagar",
        backgroundColor: color.accent,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        data: teamData.map(t => t.red)
      },
      {
        stack: "myStack",
        label: "Dagar kvar",
        backgroundColor: color.dim,
        hoverBackgroundColor: color.dim,
        data: teamData.map(t => days.comingDays.length)
      }
    ]
  };

  return (
    <div className="App">
      <section className="App-root">
        {!hideHeader && (
          <header className="App-header">
            <img
              src={require("./logo.png")}
              width="50px"
              height="50px"
              alt="Wellify logo"
            />
          </header>
        )}
        <main
          className={hideHeader ? "App-main App-main-hide-header" : "App-main"}
        >
          <h3>{competition.companyName}</h3>
          <h4>{competition.name}</h4>
          <h6>
            {competition.startDate.split("T")[0]} -{" "}
            {competition.endDate.split("T")[0]}
          </h6>
          <div className="App-chart">
            <Bar
              data={data}
              options={{
                maintainAspectRatio: false,
                legend: {
                  position: "top"
                },
                scales: {
                  xAxes: [
                    {
                      ticks: {
                        maxRotation: 270,
                        minRotation: 270,
                        padding: -50,
                        z: 1000,
                        fontColor: "#3f3f3f",
                        fontSize: 24
                      },
                      position: "bottom",

                      gridLines: false,
                      stacked: true
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        max: days.passedDays.length + days.comingDays.length
                      },
                      display: false,
                      gridLines: false,
                      stacked: true
                    }
                  ]
                }
              }}
            />
          </div>
        </main>
      </section>
    </div>
  );
}

export default App;
