export const palette = {
  black: "#1d1d1d",
  white: "#ffffff",
  offWhite: "#e6e6e6",
  wellifyGreen: "#d0dc72",
  wellifyGreenDarker: "#A7B157",
  lightGrey: "#f0f0f0",
  lighterGrey: "#CDD4DA",
  darkGrey: "#808080",
  angry: "#dd3333",
  wellifyGrey: "#9d9d9c",
  red: "#FA8072",
  transparent: "transparent",
  semiTransparent: "rgba(255,255,255,0.7)",
  blackTransparency: "rgba(255,255,255,0.9)"
};
